import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-wilmington-north-carolina.png'
import image0 from "../../images/cities/scale-model-of-wilmington-trolley-in-wilmington-north-carolina.png"
import image1 from "../../images/cities/scale-model-of-cape-fear-segway-in-wilmington-north-carolina.png"
import image2 from "../../images/cities/scale-model-of-springbrook-farms-carriage-in-wilmington-north-carolina.png"
import image3 from "../../images/cities/scale-model-of-stanley-rehder-carnivorous-plant-garden-at-piney-ridge-nature-preserve---wilmington-in-wilmington-north-carolina.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Wilmington'
            state='North Carolina'
            image={image}
            lat='34.2103894'
            lon='-77.88681170000001'
            attractions={ [{"name": "Wilmington Trolley", "vicinity": "15 S Water St, Wilmington", "types": ["point_of_interest", "establishment"], "lat": 34.2346684, "lng": -77.94943590000003}, {"name": "Cape Fear Segway", "vicinity": "602 Surry St, Wilmington", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 34.2276527, "lng": -77.94912529999999}, {"name": "Springbrook Farms Carriage", "vicinity": "16 Market St, Wilmington", "types": ["point_of_interest", "establishment"], "lat": 34.2350615, "lng": -77.94916820000003}, {"name": "Stanley Rehder Carnivorous Plant Garden at Piney Ridge Nature Preserve - Wilmington", "vicinity": "3800 Canterbury Rd, Wilmington", "types": ["park", "point_of_interest", "establishment"], "lat": 34.207096, "lng": -77.90670390000002}] }
            attractionImages={ {"Wilmington Trolley":image0,"Cape Fear Segway":image1,"Springbrook Farms Carriage":image2,"Stanley Rehder Carnivorous Plant Garden at Piney Ridge Nature Preserve - Wilmington":image3,} }
       />);
  }
}